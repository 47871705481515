/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { GenericContextProvider as ContextProvider } from '@cigna/cigna_csg_gsg_cipublic-shared/lib/context/custom/generic-provider.js';
import { ContextRoot } from '@cigna/cigna_csg_gsg_cipublic-shared/lib/context/context-root.js';
import { setupClickTracking, pageviewImpressionCheck, createImpression, } from '@cigna/cigna_csg_gsg_cipublic-shared/lib/utils/analytics/index.js';
import { CampaignsService } from '@cigna/cigna_csg_gsg_campaigns/lib/service.js';
import { cookieListener } from '@cigna/cigna_csg_gsg_cipublic-shared/lib/utils/ot-cookie-listener.js';
import { loadComponents } from './app/component-loader';
import * as contexts from './app/contexts';
import { isExternalLink } from './app/is-external-link';
import { config } from './environments/environment';
import './index.scss';
// Bootstrap the application
const logger = console;
// Provide config, functions, and services down to components
const contextProvider = new ContextProvider(document.body);
// setup context root for late providers
new ContextRoot().attach(document.body);
const campaignsService = new CampaignsService(config.api.gateways.campaigns, logger);
loadComponents();
campaignsService.getCampaignsInfo().then((campaignInfo) => {
    contextProvider.provide(contexts.dynamicPhoneNumberContext, campaignInfo['campaignPhone-cipublic']);
    contextProvider.provide(contexts.dynamicLinksContext, campaignInfo);
});
setupClickTracking(createImpression);
pageviewImpressionCheck(createImpression);
contextProvider.provide(contexts.configContext, config);
contextProvider.provide(contexts.i18nConfigContext, config.i18n);
contextProvider.provide(contexts.isExternalLinkContext, isExternalLink);
Promise.allSettled([
    customElements.whenDefined('cipublic-footer'),
    customElements.whenDefined('cipublic-list'),
]).then(() => cookieListener());
Promise.allSettled([
    customElements.whenDefined('cipublic-content-block')
]).then(() => cookieListener());
